@media all and (max-width: 1800px)  {
	.leftImg,
	.rightImg{
		display: none !important;
	}
}
@media all and (max-width: 1600px)  {
	.overlay1{
		display: none !important;
	}
}
@media all and (max-width: 1446px)  {
	.sliderText,
	#Home2 .box .BoxTitle {
	    font-size: 1.5em !important;
	}
}
@media all and (max-width: 1400px)  {
	.col-12.top {
	    font-size: 0.5em !important;
	}
	.LargeSection .BoxTitle, .SmallSection .BoxTitle {
    	font-size: 1.2em;
	}
}

@media all and (max-width: 1546px)  {
	#PageFooter{
		.col-9,
		.col-3{
			width: 100% !important;
			clear: both !important;
			text-align: center !important;
			*{text-align: center !important;}
		}
	}
	#PageFooter .fse {
	    padding-right: 0px !important;
	}

}
@media all and (max-width: 1330px)  {
	#PageFooter{
		.slink{
			display: none !important;
		}
	}
}
/*** Menu ***/
@media all and (min-width: 1540px) {
    #MenuIcon {
        display: none !important;
    }
}

@media all and (max-width: 1540px)  {

	#Menu{
		display: none;
	}
	.ActiveMenu{
		overflow: hidden !important;
		height: 100vh !important;
		#SideNav{
			right: -300px !important; 
		}
	}
}

@media all and (min-width: 1541px)  {
	.menuBgDark,
	#MobileNavigation{
		display: none !important;
	}

}
/*** Menu ***/
@media all and (max-width: 1320px)  {
/*
	#Menu{
		display: none;
	}
	.ActiveMenu{
		overflow: hidden !important;
		height: 100vh !important;
		#SideNav{
			right: -300px !important;
		}
	}*/
	#PrincipalsWelcome {
   		width: calc(100% - 40px) !important;
	}
	#PrincipalsWelcome .WelcomeInner h2 {
    	font-size: 1.5em !important;
	}
	.activeWelcome #SideNav{
		right: -300px !important;
	}
}

@media all and (max-width: 1286px)  {
	.col-five.h-3 ,
	.col-five.h-2 ,
	.col-five.h-1 {
	    width: 33.33%;
	}
	.col-five.h-4 ,
	.col-five.h-5 {
		margin-top: 40px;
	    width: 50%;
	}
}
@media all and (max-width: 1200px)  {
	.SportsAcademyInnerSection{
		width: 100% !important;
		float: left;
		clear: both;
	}
	#SportsAcademyContainer{
		width: 100% !important;
		height: auto !important;
		overflow-y: auto !important;
	}
	.SportsAcademyInnerSection{
		height: 100vh !important;
	}
	.SmallSection{
		height: 50vh !important;
	}
	.LargeSection{
		height: 100vh !important;
	}
	body{
		overflow-y: auto !important;
	}
	.scrollArrow{
		display: none !important;
	}
	#SportsAcademyFooter.mainPage{
		position: relative;
	}
	#SportsAcademyFooter.mainPage *{
		width: 100% !important;
		clear: both !important;
		text-align: center !important;
	}

	#SportsAcademyContainer{
		margin-top: 0px !important;
	}
	.HiddenPageImage{
		width: 300px !important;
		right: auto !important;
		left: 0px;
	}
/*	.HiddenPageContent{
		position: relative;
		padding: 100px 20px 100px 300px !important;
		width: 100%;
		float: left;
		min-height: 5vh;
	}*/
	.HiddenPageContent{
		padding-left: 300px !important;
	}

}
@media all and (max-width: 1343px)  {
	#pageBoxes .box .BoxTitle {
    	font-size: 1em;
	}
}
@media all and (max-height: 920px)  {
	#ScrollDown{
		display: none;
	}
}
@media all and (max-width: 900px)  {
	#pageBoxes .box{
		width: 50% !important;
		padding-bottom: 50% !important;
		.BoxTitle {
		    font-size: 1.5em !important;
		}	
	}
}
@media all and (max-width: 1080px)  {
	.HeaderImageText {
		width: 100%;
		text-align: center;
		padding: 20px !important;
		max-width: 100%;
	}
	.hideSideBarToggle{
		top: 19px !important;
	}
	#sidebar ul li a:hover span {
	    color: #ffffff !important;
	}
}
@media all and (max-width: 1000px)  {
	#sidebar .sidebar-heading a {
	    font-size: 1.2em !important;
	}

	#sidebar .sidebar-heading {
	    padding-top: 15px;
	    padding-bottom: 15px;
	}

	.sideBarContainer{
		padding-top: 0px !important; 
		*{
			color: #fff !important;
		}
	}
	#sidebar {
		background-color: #c087bc;
	}

	#breadcrumbs {
	    padding: 15px 20px !important;
	}
	.clearMid{
		clear: both;
		width: 100%;
	}

	.innerContent {
		padding-right: 20px !important;
	}
	.tacMid{
		text-align: center !important;
	}
	#sidebar .sidebar-heading a {
	    padding: 5px 20px 5px 20px !important;
	}
	#sidebar ul li a {
    	padding: 5px 20px 5px 20px !important;
	}
	#sidebar{
		margin-top: 0px !important;
		margin-bottom: 0px !important;
	}

	.sideBarContainer{
		padding-bottom: 0px !important;
	}
	#Home2 .box .BoxTitle {
		font-size: 1em !important;
	}
	
}

@media all and (max-width: 1024px)  {
	.NewsSlideInner .NewsContentContainer .NewsTitle {
	    font-size: 1.2em !important;
	}
	*{
		background-attachment: initial !important;
		-webkit-background-attachment: initial !important;
	}
}
@media all and (max-width: 1024px)  {
	.parallax{
		@include transform(translate(0%, 0%) !important);
	}
	#HomeLogo {
    	bottom: 47% !important;
    	@include transform(translate(-50%, 50%) scale(0.7) !important);
	}
	
}

@media all and (max-width: 1000px)  {
	.clearSmall{
		clear: both;
		width: 100%;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	#address {
		position: relative;
	    float: left;
	    left: auto;
	    width: 100%;
	    max-width: 100%;
	}
	#gmap_canvas {
	    min-height: 400px;
	}
	#address #addressLogo {
	    width: 140px;
	    margin-bottom: 30px;
	    margin-top: 60px;
	    position: absolute;
	    right: 17vw;
	    top: 50%;
	    @include transform(translateY(-50%));
	    margin-top: 0px;
	}
}


@media all and (max-width: 846px)  {

	#sliderOneContainer {
	    position: relative;
	    top: 0px;
	    left: 0px;
	}

	.HeaderImageText{
    	font-size: 1.7em;
	}
	.h-1,
	.h-2,
	.h-3,
	.h-4{
		width: 50% !important;
	}
	.h-3{
		margin-top: 40px;
	}
	.h-5{
		width: 100% !important;
	}
	.pageTitle {
    	font-size: 2em !important;
	}
	#Home2{
		.Section{
			clear: both;
			width: 100%;
		}
	}
	
	.welcomeSection{
		.col-6{
			clear: both;
			width: 100% !important;
			padding: 0px !important;
		}
	}
	#PrincipalsWelcome .col-8{
		clear: both;
		width: 100% !important;
	}
	#PrincipalsWelcome .col-4{
		display: none !important;
	}

}

@media all and (max-width: 768px)  {
	#address #addressLogo {
		display: none !important;
	}
	.calendar-widget-table thead th {
	    font-size: 1.5em !important;
	}
	.grid-item, .grid-sizer {  
		width: 50% !important; 
	}
	.SideBarSports{
		display: none !important;
	}
	.HiddenPageContent{
		padding: 100px 20px 100px 20px !important;
		opacity: 1 !important;

		position: relative;
		width: 100%;
		float: left;
		min-height: 50vh;
	}
	.HiddenPageImage {
	    position: relative;
	    width: 100% !important;
	    height: 50vh !important;
	    float: left !important;
	    clear: both !important;
	    right: auto !important;
	    opacity: 1 !important;
	}
	.boxGrad{
		display: none !important;
	}
	#SportsAcademyFooter.innerPage{
		position: relative;
	}
	#SportsAcademyFooter.innerPage *{
		width: 100% !important;
		clear: both !important;
		text-align: center !important;
	}


}
@media all and (max-width: 660px)  {
	#pageBoxes .box .BoxTitle {
    	font-size: 1em !important;
	}
}
@media all and (max-width: 600px)  {

	.StaffPage .staffProfile .profilePic {
		display: block;
	    width: 100%;
	    text-align: center;
	    margin-bottom: 30px;
	}
	.VerticalStack{
		text-align: center;
	}
	#HomeLogo {
    	bottom: 47% !important;
    	@include transform(translate(-50%, 50%) scale(0.5) !important);
	}
	#HomeLogo .logoText {
    	font-size: 1.4em !important;
	}
	#SideNav{
		display: none !important;
	}
	#address {
	    padding: 40px 20px;
	}
}
@media all and (max-width: 570px)  { 
	#NavBarLogo {
		left: 10px !important;
		span {
		    font-size: 0.6em;
		}
	}
	#NavBarLogo span {
	    font-size: 0.6em;
	    width: 130px;
	    line-height: 1.1em;
	    margin-top: 3px;
	}
	.h-1,
	.h-2,
	.h-3,
	.h-4,
	.h-5{
		width: 100% !important;
	}
	.h-2,
	.h-3,
	.h-4,
	.h-5{
		margin-top: 40px !important;
	}
	
	#Home3 .ReadMore ,
	#Home4 .ReadMore {
		width: 100%;
	    display: block;
	    margin-left: 0px !important;
	    margin-top: 30px;
	}
	.col-7.eventContent,
	.col-5.date {
		width: 100% !important;
		clear: both !important;
		text-align: center !important;
	}
	.EventsSlideInner .top .topInnerContainer .topInner .date:after{
		display: none !important;
	}

	#pageBoxes .box{
		width: 100% !important;
		padding-bottom: 100% !important;
	}

	.videoCenter{
		img{
			display: none !important
		}
	}
}
@media all and (max-width: 500px)  { 


	.SmallSection{
		width: 100% !important;
		height: 50vh !important;
	}
	.HeaderImageText{
    	font-size: 1.2em;
    	padding-left: 40px !important;
    	padding-right: 40px !important;
	}
	.sliderText{
		font-size: 1em !important;
	}

	.NoticesLogo{
		display: none !important;
	}
}
@media all and (max-width: 500px)  { 

	#HomeLogo{
		display: none !important;
	}
	
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
	
	#Home2{
		.box{
			width: 100%;
			padding-bottom: 100% !important;
			clear: both !important;
		}
	}
	#NavBarLogo img {
	    margin-right: 3px;
	}
}
/*** Mobile Navigation ***/
#MenuIcon{
    position: fixed;
    width: 30px;
    opacity: 1;
    height: 28px;
    z-index: 9003; 
   /*  display: none; */
    top: 7px;
    right: 60px;
    cursor: pointer;
    overflow: hidden;
    @include border-radius(3px);
    span{
      position: absolute;
      display: block;
      height: 3px;
      width: 81%;
      left: 50%;
      @include border-radius(3px);
      @include transform(translateX(-50%) rotate(0deg));
      @include transition($t1);
    }
    &:hover span{
      background-color: $textLight;
    }
    p{
      font-size: 0.8em;
      color: $textLight;
      text-transform: uppercase;
      font-weight: lighter;
      position: absolute;
      left: 50%;
      @include transform(translate(-50%));
      bottom: 12px;
    }
    span:nth-child(1){
      top: 4px;
      background-color: #c087bc;
    }
    span:nth-child(2){
     top: 11px;
    background-color: #06cdf0;
    }
    span:nth-child(3){
      top: 18px;
      background-color: #c087bc;
    }
    span:nth-child(4){
         top: 18px;
    }
}

.mobileNavBg {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  z-index: 6005;
  visibility: hidden;
  @include transition(all 1s);
  opacity: 0;
}

/*** Menu ***/
#MenuBg{
  position: absolute;
  width: 0%;
  height: 300%;
  left: 50%;
  top: 50%;
  background-color: #fff;
  @include transform(translate(-50%, -50%) rotate(-45deg));
  @include transition(all 1s ease-in-out);
  display: none !important;
}
.subMenuBox{
display: none !important;
  position: absolute;
  left: -400px;
  width: 300px;
  margin-top: -30px;

  *{
    color: #fff !important;
  }
  a{
    opacity: 0;
    display: block;
    padding: 0px !important;
    background-color: transparent !important;
  /*  &:hover{
      color: #c087bc !important;
    }*/
  }
}
.subMenuBox a.active{
  color: #c087bc !important;
}

.subMenuBoxLinks{
display: none !important;
  padding: 20px;
  z-index: 7;
  a{
    opacity: 0;
    text-transform: uppercase;
  }
}
.menuInnerBg{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 300px;
  height: 100%;
  background-color: #fff;
}

.rectangle{
    position: absolute;
    width: 100%;
    height: 100%;
    @include transform(rotateY(180deg));
    rect{
      stroke: #c087bc;
      fill: transparent;
      stroke-width: 4;
    }
}

.line{
    position: absolute;
    width: 360px;
    height: 30px;
    right: 2px;
    @include transform(translateX(100%) rotateY(180deg)  rotateX(180deg));
    rect{
      stroke: #c087bc;
      fill: transparent;
      stroke-width: 4;
    }
}
.Level1.active{
  .line rect{
      stroke-dashoffset: 390px !important;
    }
   .rectangle{
    
    rect{
        stroke-dashoffset: 0 !important;
      }
  }
  .subMenuBoxLinks a{
    opacity: 1 !important;
  }
}
#MobileNavigation{
  opacity: 0;
  @include transition(all 1s);
  position: fixed;
  z-index: 9000;
  top: 0px;
  right: -700px;
  height: 100%;
  font-size: 0.8em;
  overflow: hidden;
  width: 700px;
  .toggleNav{
    cursor: pointer;
    background-color: transparent;
    position: absolute;
    right: 0px;
    font-weight: lighter;
    top: 5px;
    width: 30px;
    height: 30px;
    z-indeX: 90;
    color: #000;
    font-size: 22px;
    border: none;
    outline: none;
    /*display: none;*/
  }
  .TopLevel{
    padding: 10px;
    position: absolute;
    display: inline-block;
    right: 0px;
    height: 100%;
    max-height: calc(100% - 120px);
    top: 60px;
    overflow-y: auto;
    @include border-radius(0px);
    width: 700px;
    padding-left: 400px;
    text-align: left;

    &:before{
      content: '';
      position: absolute;
      display: block;
      border-left: 1px solid #fff;
      border-top: 1px solid #fff;
      opacity: 0.6;
      height: 50px;
      width: 50px;
      top: -20px;
      left: -20px;
    }
  }

  li:hover{
    .level-1-link{
        color: #c087bc !important;
    }
  }
  ul{
    ul{
      display: none;
      background-color: rgba(0,0,0,0.05);
    }
    li{
      position: relative;

      a{
        color: #000;
        text-align: left;
        font-size: 1em;
        width: 100%;
        z-index: 80;
        position: relative;
        text-transform: uppercase;
        display: block;
        padding: 10px 34px 10px 20px;
        text-decoration: none !important;
        @include transition($t1);
        &:hover{
          color: #c087bc !important;
        }
      }
    }
  }
}

.menuBgDark{
  position: fixed;
  display: none;
  z-index: 9000;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100%;
  background-color: rgba(000, 000, 000, 0.8);
}
.ActiveMenu{
  /*overflow: hidden !important;
  height: 100vh !important;*/
  #SportsAcademy,
  #BottomBar {
    position: fixed;
   /* z-index: 9999;*/
  }

  #MobileNavigation{
    display: block;
    opacity: 1 !important;
    margin-top: 0px !important ;
    height: 100%;
    right: 0% !important;
  }
  .mobileNavBg {
    visibility: visible;
    opacity: 1;
  }
  #MenuBg{
    width: 200%;
    height: 300%;
  }
   #MenuIcon {
     span:nth-child(1){
        top: 12px;
            background-color: #06cdf0 !important;
        @include transform(translateX(-50%)  rotate(45deg));
      }
      span:nth-child(3){
        @include transform(translateX(-50%)  rotate(-45deg));
        top: 12px;
      }
      span:nth-child(2){
        margin-left: 100px !important;
        opacity: 0 !important;
        width: 50%;
      }
      span:nth-child(4){
        opacity: 0 !important;
      } 
  }
}

.close{
  z-index: 500;
}
.close,
.closeMenu{
  position: absolute;
  top: 30px;
  left: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
/*  background-color: #b3bdc1;*/
    border-radius: 3px;
}
.closeMenu{
  top: 31px;
    z-index: 300;
}
.close span,
.closeMenu span{
  position: absolute;
  content:'';
  display: block;
  width: 100%;
  height: 3px;
  background-color: #fff;
  width: 80%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  transition: all 0.35s;
  -o-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -webkit-transition: all 0.35s;
}
.close span:nth-child(1),
.closeMenu span:nth-child(1){
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

.close span:nth-child(2),
.closeMenu span:nth-child(2){
  transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -o-transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -moz-transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -webkit-transform:translateX(-50%) translateY(-50%) rotate(45deg);
}

.close:hover span:nth-child(1),
.closeMenu:hover span:nth-child(1){
  transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -o-transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -moz-transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -webkit-transform:translateX(-50%) translateY(-50%) rotate(45deg);
}
.close:hover span:nth-child(2),
.closeMenu:hover span:nth-child(2){
  transform:translateX(-50%) translateY(-50%) rotate(-45deg);
  -o-transform:translateX(-50%) translateY(-50%) rotate(-45deg);
  -ms-transform:translateX(-50%) translateY(-50%) rotate(-45deg);
  -moz-transform:translateX(-50%) translateY(-50%) rotate(-45deg);
  -webkit-transform:translateX(-50%) translateY(-50%) rotate(-45deg);
}

/*** Scroll Bar ***/
.TopLevel::-webkit-scrollbar{
  width: 6px;
  height: 9px;
}
.TopLevel::-webkit-scrollbar-button:start:decrement,
.TopLevel::-webkit-scrollbar-button:end:increment{
  display:block;
  height:0;
  background-color:transparent;
}
.TopLevel::-webkit-scrollbar-track-piece{
  background-color:#eee;
  @include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb{
  background-color: #656571;
  @include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb:vertical{
}
.TopLevel::-webkit-scrollbar-thumb:horizontal{
}
.TopLevel::-webkit-scrollbar-thumb:hover{
  background-color: #656571;
}
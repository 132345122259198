*{
	font-family: $font-stack;
	color: $textDark;
	font-weight: lighter;
	text-decoration: none;
	/*outline: none !important;*/
}

*{
   /*-webkit-appearance: none !important;
   -moz-appearance:    none !important;
   appearance:         none !important;*/
}

body{
	overflow-y: auto;
	overflow-x: hidden;
	background-color: #2b2b2b;
	@include transition(background-color 0.35s 0.3s);
}
.SportsAcademyInnerPage{
	/*@include transition(background-color 0s 0s !important);*/
}

.calendar-widget-table tbody .calendar-day:hover, .calendar-widget tbody .show-week:hover, .calendar-widget tbody .selected {
    background-color: rgba(192, 135, 188, 0.6);
}

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}
.bgWhite{
	background-color: #fff;
}

/* ****** */
.typography .left,
.typography .right,
.typography .center,
.typography .leftAlone,
.typography .rightAlone {
	/*margin: 10px;*/
	    margin: 22px;
}
/* ****** */
/*
|===============================================================
|	Video Page
|===============================================================
*/
/*** Video Page ***/
.videoHeader{
	height: 126px;
	background-color: #000;
}
.fullScreenBlackout{
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	background-color: #000;
	height: 100%;
	z-index: 9998;
}
.videoContainer{
    padding-bottom: 38%;
	overflow: hidden;
}
.video_background{
/*
	width: 125% !important;
	height: 114% !important;
*/
    width: 151% !important;
    height: 152% !important;
	position: absolute !important;
	top: 50%;
	left: 50%;
	@include transform($center);
}

.active.video_background{
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 9999;
}
.active{
	.closeVideo{
		display: block !important;
	}
}


.videoBlackout{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	@include transition($t1);
	height: 100%;
	background-color: rgba(0,0,0, 0.6);
}

.videoContainer:hover{
	.videoBlackout{
		background-color: rgba(0,0,0, 0.4) !important;
	}
}

.videoTitle{
	 font-family: geomanistblack;
	 font-size: 2.8em;
	 color: #fff !important;
	 margin-bottom: 20px;
	 text-align: center;
}
.mejs__container * {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.closeVideo{
	display: none;
	position: fixed;
	top: 10px;
	right: 10px;
	z-index: 9999;
	cursor: pointer;
	color: #fff;
	font-size: 1.8em;
	i{color: #fff;}
}

.videoCenter{
	display: inline-block;
	position: absolute;
	left: 50%;
    top: 50%;
    @include transform($center);
    img{
    	max-width: 110px;
	    display: block;
	    margin: 0 auto;
    	margin-bottom: 30px;
    }
}
.watchFull{
	cursor: pointer;
	margin: 0 auto;
   	display: block;
    min-width: 157px;
    text-align: center;
	border: 1px solid #fff;
	padding: 6px 20px 8px 20px;
    background-color: transparent !important;
    text-transform: uppercase;
    color: #c087bc !important;
    &:hover{
    	color: #fff !important;
		border: 1px solid #c087bc !important;
    	background-color: #c087bc !important;
    }
}

.video_background.active{
	background-color: #000 !important;
}

.VideoCenter{
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 5;
	@include transform($center);
}

.videoWrapper .videoContainer:nth-child(odd):last-of-type{
	padding-bottom: 50% !important;
	width: 100% !important;
}
@media all and (max-width: 860px)  {
	.videoContainer{
		padding-bottom: 50%;
		width: 100%;
	}
	.videoTitle{
		font-size: 1.8em !important;
	}
}

body.oh{
	overflow: hidden !important;
	overflow-x: hidden !important;
	overflow-y: hidden !important;
	max-height: 100vh;
}
.oh{
	overflow: hidden !important;
}
/*** Video Page ***/

/*
|===============================================================
|	Website Sitemap
|===============================================================
*/

/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:8px;
	height:9px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0;
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color:#2b2b2b;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: #656571;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: #656571;
}


#pageBoxes{
	overflow: hidden;
	max-width: 100vw !important;
}

.calendar-widget-table tbody .today {
    color: #c087bc;
}
.calendar-widget-table thead th {
    background-color: #c087bc;
}

.calendar-widget-table{
	i{
		color: #fff !important;
	}
}

.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}
#allEvents{
	width: 100%;
	max-width: 700px;
	float: left;
	clear: both;
	border-top: 2px solid #eee;
	padding: 20px 0px;
	display: block;
	position: relative;
	ul{margin: 0px;padding: 0px;
		float: left;
		clear: both;
		width: 100%;
		display: block;
		li{
			margin: 3px 0px !important;
			padding: 10px;
			float: left;
			width: 100%;
			clear: both;
			@include border-radius(3px);
			opacity: 1;
			cursor: pointer;
			position: relative;
			list-style: none !important;
			@include transition($t1);
			background-color: $themeColor;
			*{color: #fff;}
			&:hover{
				opacity: 0.9;
			}
			sup{
				margin-right: 10px;
			}
			a{
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
			}
		}
	} 
}



.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
} 
.flex{
	display: flex;
}

.PageImg{
	padding-top: 50px;
}

/*** Staff, Vacancy and Newsletters start ***/
.noticesBG{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	background-color: rgba(43, 43, 43, 0.7);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	display: none;
	left: 50%;
	@include transform($center);
	/*padding: 30px;*/
	/*background-color: #060505;
	max-width: 100%;
	width: 500px;
*/
	max-width: 500px;
	width: 100%;
	background-color: #ffffff;
    box-shadow: 0px 1px 2px 0px #e0e2ea;
    border: 1px solid #daddea;
    padding: 20px 20px 60px 20px;
    @include border-radius(3px);

    section{
    	&:after{
    		content:'';
    		display: block;
    		position: absolute;
    		height: 2px;
    		width: 40px;
    		background-color: #c087bc;
    		bottom: 5px;
    		left: 50%;
    		@include transform(translateX(-50%));
    	}
    	*{
    		opacity: 0;
    		@include transition(opacity 1s);
    	}
    }

    .bx-controls.bx-has-pager {
	    position: absolute;
	    top: -20px;
	    left: 50%;
	    @include transform(translateX(-50%));
	}
    .bx-pager-item{ 
    	display: inline-block;
    	a{
	    	display: block;
		    display: inline-block;
		    height: 12px;
		    width: 12px;
		    opacity: 0.7;
		    background-color: #494952;
		    color: transparent;
		    position: relative;
		    margin: 3px;
		    @include border-radius(50%);
		    @include transition($t1);
		    &:hover{
	  			opacity: 1 !important;
		    }
		}
	}
	.bx-pager-item a.active {
  		opacity: 1 !important;
	}
}
.noticesContainer.active{
 	section{
		*{
    		opacity: 1 !important;
    	}
    }
}
.NoticeTitle{
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px !important;
    border-bottom: none !important;
    padding-bottom: 30px !important;
    font-size: 1.3em;
}
.readMoreLink{
	@include border-radius(3px);
	margin-top: 10px;
	font-size: 0.8em;
 	display: inline-block;
    padding: 6px 20px;
    color: #fff !important;
    background-color: #484853;
    i,
    span{
    	color: #fff !important;
    }
    @include transition($t1);
	&:hover{
		background-color: #c087bc !important;
	}
}


/*.SiteVisitedDisableIntro{
	#IntroWhiteOut,
	.noticesBG,
	.noticesContainer{
		display: none !important;
	}	
}*/
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	position: absolute;
	bottom: 10px;
	left: 50%;
	@include transform(translateX(-50%));
	padding-right: 20px;
	color: #666;
	font-size: 0.8em;
	cursor: pointer;
	z-index: 9999; 
	height: 40px;
	width: 40px;

}
.closeNotices:after {
    content: "";
    height: 2px;
    width: 20px;
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    background-color: #c087bc;
    @include transform($center rotate(-45deg));
}

.closeNotices:before {
    content: "";
    height: 2px;
    width: 20px;
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    background-color: #06cdf0;
    @include transform($center rotate(45deg));
}
.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 10px;
    text-align: center;
	*{
		color: #666;
		font-weight: lighter;
	}

}

.leftNoticesContainer {
	display: none;
}

.showNotices .leftNoticesContainer {
	display: block;
	width: 750px;
	max-width: calc(100% - 20px);
	overflow: hidden;
	position: absolute;
    bottom: 60px;
	left: 20px;
	z-index: 10;

	#leftNoticesSliderContainer {
		background: #fff;
		.leftInner {
			display: flex;
			img {
				display: block;
				max-width: 30%;
				object-fit: cover;
			}
			.leftMessage {
				width: 70%;
				min-height: 207px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
			.leftTitle {
				text-transform: uppercase;
				font-size: 1.5em;
				color: $themeColor;
			}
			.readMoreLink {
				border-radius: 0px;
				text-transform: uppercase;
				justify-self: flex-end;
			}
		}

		.bx-controls.bx-has-pager {
			position: absolute;
			bottom: 20px;
			right: 100px;
			z-index: 50;
		}
		.bx-pager-item{ 
			display: inline-block;
			a{
				display: block;
				display: inline-block;
				height: 12px;
				width: 12px;
				opacity: 0.7;
				background-color: #494952;
				color: transparent;
				position: relative;
				margin: 3px;
				@include border-radius(50%);
				@include transition($t1);
				&:hover{
					  opacity: 1 !important;
				}
			}
		}
		.bx-pager-item a.active {
			  opacity: 1 !important;
		}
	}

	.leftCloseNotices{
		position: absolute;
		bottom: 20px;
		right: 30px;
		color: #666;
		font-size: 0.8em;
		cursor: pointer;
		z-index: 9999; 
		height: 40px;
		width: 40px;
	
	}
	.leftCloseNotices:after {
		content: "";
		height: 2px;
		width: 20px;
		position: absolute;
		display: block;
		left: 50%;
		top: 50%;
		background-color: $themeColor;
		@include transform($center rotate(-45deg));
	}
	
	.leftCloseNotices:before {
		content: "";
		height: 2px;
		width: 20px;
		position: absolute;
		display: block;
		left: 50%;
		top: 50%;
		background-color: #06cdf0;
		@include transform($center rotate(45deg));
	}
}

.rightNoticesContainer {
	display: none;
}

.showNotices .rightNoticesContainer {
	display: block;
	width: 500px;
	max-width: calc(100% - 20px);
	overflow: hidden;
	position: fixed;
    bottom: 60px;
	right: 20px;
	z-index: 9999;

	transition: all 0.3s;

	&.scrolled {
		bottom: 0px;
	}

	#rightNoticesSliderContainer {
		background: $themeColor;
		color: #fff;
		.rightInner {
			display: flex;
			.rightMessage {
				width: 100%;
				min-height: 207px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				* {
					color: #fff;
				}
			}
			.rightTitle {
				text-transform: uppercase;
				font-size: 1.5em;
			}
			.readMoreLink {
				border-radius: 0px;
				text-transform: uppercase;
				justify-self: flex-end;
			}
		}

		.bx-controls.bx-has-pager {
			position: absolute;
			bottom: 20px;
			right: 100px;
			z-index: 50;
		}
		.bx-pager-item{ 
			display: inline-block;
			a{
				display: block;
				display: inline-block;
				height: 12px;
				width: 12px;
				opacity: 1;
				background-color: #fff;
				color: transparent;
				position: relative;
				margin: 3px;
				@include border-radius(50%);
				@include transition($t1);
				&:hover{
					  background-color: #494952;
				}
			}
		}
		.bx-pager-item a.active {
			background-color: #494952;
		}
	}

	.rightCloseNotices{
		position: absolute;
		bottom: 20px;
		right: 30px;
		color: #666;
		font-size: 0.8em;
		cursor: pointer;
		z-index: 9999; 
		height: 40px;
		width: 40px;
	
	}
	.rightCloseNotices:after {
		content: "";
		height: 2px;
		width: 20px;
		position: absolute;
		display: block;
		right: 50%;
		top: 50%;
		background-color: #fff;
		@include transform($center rotate(-45deg));
	}
	
	.rightCloseNotices:before {
		content: "";
		height: 2px;
		width: 20px;
		position: absolute;
		display: block;
		right: 50%;
		top: 50%;
		background-color: #06cdf0;
		@include transform($center rotate(45deg));
	}
}


.FormContainer,
#content{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff;
		    background-color: #17aae4;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transform($t1);
		    &:hover{
		    	background-color: #2F3B67 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #009e7f;
		}
	}
}


/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	display: inline-block;
	width: 33.33%;
	min-width: 180px;
	min-height: 180px;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px;
		background-color: #A1A1A3;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
			color: #fff;
		}
		&:hover{
			background-color: #c087bc;
		}
	}


}
.SubDate{
	color: #c087bc;
	font-size: 1.4em;
}
.veventContainer .vevent,
.vacancies .vacancy{
	float: left;
	width: 100%;
	clear: both;
	border-bottom: 2px solid #eee !important;
	padding: 30px 0px;
}

.veventContainer .vevent:last-of-type,
.vacancies .vacancy:last-of-type{
	border-bottom: none !important;
}




.news-teaser{
	padding: 30px 0px;

	border-bottom: 2px solid #eee;
    &:last-of-type{
    	border-bottom: none !important;
    }
	img{
		@include border-radius(50%);
	}
	a h3 {
	    color: #c18ec0 !important;
	    font-size: 1.3em !important;
	    font-weight: lighter;
	    text-transform: uppercase;
	}
}

.addToCalendar,
.button{
	display: inline-block;
    padding: 6px 20px;
    color: #fff !important;
    background-color: #484853;
    i,
    span{
    	color: #fff !important;
    }
    @include transition($t1);
	&:hover{
		background-color: #c087bc !important;
	}
}

.upload{
	position: relative;
	display: block;
	width: 100%;
	/* max-width: 500px; */
	clear: both;
	float: left;
	padding: 10px 60px 10px 30px;
	border: 1px solid #c087bc;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle{
		text-transform: uppercase;
		font-size: 1em;
		line-height: 1.7em;
	    display: inline-block;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon{
	    background-color: #c087bc;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);

	    top: 50%;
		position: absolute;
		right: 16px;
		@include transform(translateY(-50%));

	    span{
	    	@include transition($t1);
	    	color: #fff;
		    background-color: #102d40;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover{
		background-color: #eee  !important;
		color: #000 !important;
		.uploadTitle{
			/*color: #000 !important;*/
		}
		/*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
	}
}


.upload{
	/*padding: 10px;
	border-bottom: 2px solid #eee;*/
	*{
		vertical-align: middle;
		font-weight: lighter !important;
	}
}





.Newsletter{
	width: 33.33%;
    padding-bottom: 33.33%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute;
	border: 2px solid #e7e7e7;
	background-color: #efefef;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	background-color: #f5f5f5;
	border: 2px solid #e7e7e7;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	color: $themeColor !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	font-weight: lighter;
	color: $themeColor !important;
	font-weight: lighter;
	*{
		font-weight: lighter;
		color: $themeColor !important;
	}
}



.attachment{
    display: block;
	background-color: #484853;
    border-radius: 3px;
    max-width: 400px;
    color: #fff !important;
    padding: 5px 20px;
    margin-top: 5px;
    cursor: pointer;
    @include transition($t1);
    &:hover{
    	background-color: #c087bc;
    }
}

.vacancy{
    padding: 30px 0px;
    border-bottom: 2px solid #eee;
}

.removalDate{
	margin-bottom: 0px !important;
	font-weight: lighter;
	color: #000000 !important;
}

#sliderEvents{
	padding: 0px !important;
}

#sliderEvents section{
	float: left;
	clear: both;
}

.clear{
	clear: both;
}


.pageTitle{
	font-weight: lighter;
	font-size: 4em;
	text-transform: uppercase;
	color: #0759a7;
}

img{
	max-width: 100%;
}

.searchToggle{
	right: 69px;
    background-color: #0759a7;
	@include transition($t1);
}
.translate {
    background-color: #4d4d4c;
    right: 30px;
    img{
    	max-width: 24px;
	    position: absolute;
	    left: 50%;
	    @include transform(translateX(-50%));
	    top: 4px;
    }
}
.searchToggleButton{
	position: absolute;
	left: 50%;
	top: 7px;
	@include transform(translateX(-50%));
}


.Group1{
	/* opacity: 0; */
}




/*** Video Page ***/
/*.videoHeader{
	height: 120px;
	background-color: #000;
}
.fullScreenBlackout{
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	background-color: #000;
	height: 100%;
	z-index: 9998;
}
.videoContainer{
	padding-bottom: 25%;
	overflow: hidden;
}
.video_background{
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform($center);
}

.active.video_background{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
.active{
	.closeVideo{
		display: block !important;
	}
}

.videoBlackout{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0, 0.4);
}

.closeVideo{
	display: none;
	position: fixed;
	top: 10px;
	right: 10px;
	z-index: 9999;
	cursor: pointer;
	color: #fff;
	font-size: 1.8em;
	i{color: #fff;}
}

.watchFull{
	cursor: pointer;
	position: absolute;
	color: #fff;
	top: 50%;
	left: 50%;
	z-index: 5;
	@include transform($center);
}
.videoWrapper .videoContainer:nth-child(odd):last-of-type{
	padding-bottom: 50% !important;
	width: 100% !important;
}
@media all and (max-width: 860px)  {
	.videoContainer{
		padding-bottom: 50%;
		width: 100%;
	}
}*/
/*** Video Page ***/

.db{
	display: block;
}

fieldset{
	border: none;
	.field.checkbox {
		.middleColumn {
			display:inline-block;
		}
	}
	
}

fieldset{
	border: none;
	.field {
		position: relative;
		.message {
			display: block;
			border:2px solid red;
			/*position: absolute;*/
		    top: 0;
		    left: 30px;
		    background-color: #EF6C82;
		    padding:5px;
	    	border-radius:5px;
    	    color: #FFF;
		    
		}
	}
}

/*** Loading Bar ***/
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #c087bc;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}
.pace.pace-inactive {
  display: none;
}



/*********/
/*********/
/*.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  z-index: 9999;
  position: fixed;
  height: 90px;
  width: 90px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
}

.pace.pace-inactive .pace-activity {
  display: none;
}

.pace .pace-activity {
  position: fixed;
  z-index: 9999;
  display: block;
  position: absolute;
  left: -30px;
  top: -30px;
  height: 90px;
  width: 90px;
  display: block;
  border-width: 30px;
  border-style: double;
  border-color: #c087bc #efefef #efefef;
  border-radius: 50%;

  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  -o-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}*/


/*@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(359deg); }
}

@-moz-keyframes spin {
  100% { -moz-transform: rotate(359deg); }
}

@-o-keyframes spin {
  100% { -moz-transform: rotate(359deg); }
}

@keyframes spin {
  100% {  transform: rotate(359deg); }
}*/
/*********/
/*********/

/*** Page Loading ***/
.Loading{
	height: 100vh;
	overflow: hidden;
}
.pace-running{
	overflow: hidden;
}

/*** Page Loading Complete ***/
.pace-done{
	.Loading{
		height: 0%;
		opacity: 0;
	}
}

.darken{
	@include stretchCover(rgba(43,43,43, 0.5), absolute, 1)
}

#gmap_canvas{
	min-height: 800px;
}

/*** Footer ***/

.SearchContainer{
    display: none;
    position: fixed;
    top: -5px;
    background-color: #676773;
    padding: 7px;
    right: 0px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: rgb(141, 164, 68);
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    @include border-radius(0px !important);
 

	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
    	@include border-radius(0px !important);
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 6px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #c087bc;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px 7px 10px;
	    color: #5f5e6d;
	    float: left;
    	@include border-radius(0px !important);
    	display: block;
    }
} 


.translate{
	cursor: pointer;
}
.TranslateBG{
	display: none;
	position: fixed;
	top: 0px;
	z-index: 9999;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0, 0.7);
}
#google_translate_element{
	display: none;
	position: fixed; 
	z-index: 9999;
	top: 50%;
	left: 50%;
	@include transform($center);
}

#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
		padding: 30px 0px;
		border-bottom: 2px solid #eee;
		margin: 0px;
		display: block;
		float: left;
		width: 100%;
		list-style: none !important;
	} 
}

.bannerGrad{
	position: absolute;
	opacity: 0.7;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2b2c2e+0,2b2c2e+50,2b2c2e+100&1+0,0.21+50,1+100 */
	background: -moz-linear-gradient(left,  rgba(43,44,46,1) 0%, rgba(43,44,46,0.21) 50%, rgba(43,44,46,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  rgba(43,44,46,1) 0%,rgba(43,44,46,0.21) 50%,rgba(43,44,46,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  rgba(43,44,46,1) 0%,rgba(43,44,46,0.21) 50%,rgba(43,44,46,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2b2c2e', endColorstr='#2b2c2e',GradientType=1 ); /* IE6-9 */
}

/*** Site build ***/
#NavBarLogo{
	z-index: 9999;
	position: fixed;
	top: 7px;
	left: 50px;
	opacity: 1;
	a{
		display: inline-block;
		*{
			vertical-align: top;
			display: inline-block;
		}
	}
	img{
		max-width: 50px;
		margin-right: 15px;
	}
	span{
		text-transform: uppercase;
		color: #fff;
		font-size: 1.1em;
	}
}

#SportsAcademy{
	    width: 225px !important;
    text-align: center;
	position: absolute;
	z-index: 9001;
	left: 50%;
	bottom: 0px;
	opacity: 1;
	@include transform(translateX(-50%));
	.LinkText{
		display: inline-block;
		color: #06cdf0;
		text-transform: uppercase;
		background-color: #fff;
    	height: 40px;
    	padding: 8px 13px;
	}
	*{
		vertical-align: middle;
	}
	.LinkImage{
		height: 40px;
		text-align: center;
		display: inline-block;
		background-color: #06cdf0;
		width: 40px;
    	padding-top: 6px;
	}
}

.overlay1{
	position: absolute;
	bottom: 0px;
	left: 0px;
	bottom: 0px;
	opacity: 1;
	img{
		display: block;
	}
}

.HomePage{
	.overlay1{
		bottom: 240px;
	}
}

#HomeBanner{
	background-image: url(../images/img1.jpg);
	background-size: cover;
	background-position: center;
	position: relative;
	width: 100%;
	clear: both;
	float: left;
	overflow: hidden;
	height: 100vh;
	background-color: #2b2b2b;
}
.pageBanner{
	background-image: url(../images/img1.jpg);
	background-size: cover;
	background-position: center;
	position: relative;
	width: 100%;
	clear: both;
	float: left;
	overflow: hidden;
	height: 50vh;
	background-color: #2b2b2b;
}
.quoteBox {
    position: absolute;
    top: 50%;
    left: 50px;
    @include transform(translate(0%, -50%));
    z-index: 2;
    color: #fff;
    font-size: 2em;
    display: block;
    opacity: 1;
    line-height: 1.2em;
    text-transform: uppercase;
    *{
    	color: #fff !important;
    }
}
#HomeLogo{
	opacity: 0 !important;
	@include transition($t1);
}
.parallaxReady{
	#HomeLogo{
		opacity: 1 !important;
	}
}

#IntroWhiteOut{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: #fff;
}
.flexslider {
	background-color: #2b2b2b !important;
}

#HomeLogo{
	position: absolute;
	/*bottom: 50%;*/
	bottom: 57%;
	left: 50%; 
	@include transition(all 1s);
	@include transform(translate(-50%, 50%));
	.prt2{
		opacity: 1;
		z-index: 3;
		top: 0px;
		margin-top: 0vh;
		position: absolute;
	    left: 50%;
	    @include transform($center);
	}
	.prt1{
		opacity: 1;
		display: block;
	}
	.prt3{
		z-index: 3;
		opacity: 1;
		position: absolute;
	    left: 50%;
	    bottom: 32px;
	    margin-bottom: 0vh;
	    max-width: none;
	    @include transform(translate(-50%, 50%));
	}

	.logoText{
		text-transform: uppercase;
		position: absolute;
		left: 50%;
		@include transform(translateX(-50%));
		bottom: -70px;
	    font-size: 1.8em;
	    width: 700px;
	    text-align: center;
		@include transition(all 1s);
	    color: #fff;
	    font-weight: lighter;
	    opacity: 0;
	}
}

.searchToggle{
	position: fixed;
	z-index: 9999;
	top: 0px;
    background-color: #06cdf0;
    cursor: pointer;
	right: 0px;
	height: 40px;
	width: 40px;
	text-align: center;
	padding-top: 8px;
	font-size: 1.1em;
	opacity: 1;
	*{
		color: #fff;
	}
}

#TopBar{
	position: fixed;
	top: 0px;
	left: 0px;
	height: 40px;
	width: 100%;
	z-index: 9002;
	background-color: #484853;
}
#BottomBar{
	position: absolute;
	background-color: #484853;
	bottom: 0px;
	right: 0px;
	height: 40px;
	width: 100%;
	z-index: 9001;
}

.social{
	z-index: 9999;
	opacity: 1;
	position: fixed;
	top: 50px;
    right: 10px;
	i{
		@include transition($t1);
		color: #808087;
	}
	a{
		padding: 8px;
    	display: inline-block;
	}
	a:hover{
		i{color: #06cdf0 !important;}
	}
}

.socialLink{
	@include transition($t1);
	opacity: 1;
}

#Social {
	position: absolute;
	top: 90px;
	right: 10px;
	a{
		margin-top: 5px;
		height: 26px;
	    width: 26px;
	    display: block;
	    @include border-radius(50%);
		opacity: 1 !important;
		position: relative;
		display: block;
		border: 1px solid #fff;
		text-align: center;
		font-size: 0.8em;
		padding-top: 3px;
		@include transition($t1);
		&:before{
		    @include transition($t1);
		    /******/
		    font-size: 1em;
    		text-align: left;
		    width: 50px;
		    content: attr(data-text);
		    position: absolute;
		    display: block;
		    right: 0px;
		    overflow: hidden;
		    height: 24px;
		    top: -1px;
		    border: 1px solid #fff;
		    color: #fff;
		    font-size: 0.8em;
		    text-align: left;
		    line-height: 2.4em;
		    text-transform: uppercase;
		    padding-left: 10px;
		    border-radius: 42px;
		    opacity: 0;
		    /******/
		}
	}
	i{
		color: #fff;
		@include transform(rotate(0deg));
		@include transition($t1);
	}
	
}
#Social a:hover{
	background-color: #fff;
	&:before{
		width: 98px;
		opacity: 1;
	}
	i{
		color: #06cdf0 !important;
		@include transform(rotate(360deg));
	}
	
}

.Loading{
	.prt2{
		opacity: 0 !important;
		margin-top: -100vh !important;
	}
	.prt1{
		opacity: 0 !important;
	}
	.prt3{
		opacity: 0 !important;
		margin-bottom: -100vh !important; 
	}

}
.intro{
	#TopBar,
	#BottomBar{
		width: 0% !important;
	}
	#SideNav,
	#welcomeToggle,
	.socialLink,
	.searchToggle,
	.overlay1,
	#MenuIcon,
	#SportsAcademy,
	#NavBarLogo,
    .MenuContainer{
		opacity: 0 !important;
	}
}

#welcomeToggle{ 
	cursor: pointer;
	opacity: 1;
	position: absolute;
    top: 48%;
    left: -105px;
    @include transform(rotate(-90deg));
	.toggleText{
		padding: 10px;
		font-size: 1.1em;
		text-transform: uppercase;
		color: #fff;
		display: inline-block;
	}
	.toggleImage{
		display: inline-block;
		height: 40px;
		width: 40px;
		background-color: #c087bc;
		text-align: center;
		padding-top: 10px;
		img{
			@include transition($t1);
			@include transform(rotate(90deg));
		}
	}
	*{
		vertical-align: top;
	}
}
#PrincipalsWelcome{
	position: absolute;top: 0px;
	left: -100%;
	width: 50%;
	opacity: 0;
	height: 100%;
	@include transition(all 1s);
	background-color: #fff;
	z-index: 9000;
	.WelcomeInner{
		width: 80%;
		max-height: 80%;
		overflow: hidden;
		overflow-y: auto;
		padding: 20px;
		h2{
			text-transform: uppercase;
			padding: 20px 0px;
			margin: 0px;
		}
		.logo{
			width: 180px;
		}
	}
	.welcomeSection{
		font-size: 0.8em;
		*{
			font-weight: lighter;
		}
		b, strong{
			color: #c087bc !important;
			font-weight: bold !important;
		}
	}
	.col-6 {
		p:last-of-type{
			margin-bottom: 0px !important;
		}
	}
}

ul.flex-direction-nav {
    position: absolute;
    /*bottom: 7%;*/
	bottom: 134px;
    z-index: 9999;
    width: 70px;
    left: 50%;
    height: 30px;
    @include transform( translate(-50%, 0%));
}

.SliderImage{
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.WelcomeInner::-webkit-scrollbar{
	width: 4px;
	height: 9px;
}
.WelcomeInner::-webkit-scrollbar-button:start:decrement,
.WelcomeInner::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0;
	background-color:transparent;
}
.WelcomeInner::-webkit-scrollbar-track-piece{
	background-color:#eee;
	@include border-radius(0px);
}
.WelcomeInner::-webkit-scrollbar-thumb{
	background-color: #656571;
	@include border-radius(0px);
}
.WelcomeInner::-webkit-scrollbar-thumb:vertical{
}
.WelcomeInner::-webkit-scrollbar-thumb:horizontal{
}
.WelcomeInner::-webkit-scrollbar-thumb:hover{
	background-color: #656571;
}
.activeWelcome{
	#PrincipalsWelcome{
		left: 40px;
		opacity: 1 !important;
	}
	#welcomeToggle{ 
		.toggleImage img{
			@include transform(rotate(-90deg));
		}
	}
}

.principal{
	max-width: 140px;
}
.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 113%;
	background-image: url(../images/img1.jpg);
	background-size: cover;
	background-position: center;
}
.bg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 113%;
	background-image: url(../images/img1.jpg);
	background-size: cover;
	background-position: center;
}
.intro{
	height: 100vh !important;
	overflow: hidden !important;
}



.IntroComplete{
	#IntroWhiteOut{
		display: none !important;
	}
	#HomeLogo{
		/*bottom: 40% !important;*/
		bottom: 520px !important;
		width: 180px;
	}
	.logoText{
		opacity: 1 !important;
	}
}

.flexslider .slides li {
    position: relative;
    height: 100vh;
}
.Search_results,
.pageBanner{
	.flexslider .slides li {
	    position: relative;
	    height: 50vh !important;
	}	
	.slideBG{
	    height: 50vh !important;
	}
}
#pageBoxes,
#Home2{
	background-color: #2b2b2b;
}
.box{
	overflow: hidden;
	padding-bottom: 50%;
	.boxbg{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-image: url(../images/img1.jpg);
		background-size: cover;
		background-position: center;
		@include transition(1s);
		@include transform(scale(1));
	}
	
	.boxDarken{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 101%;
		height: 101%;
		background-color: rgba(43,43,43,0.89);
	}
	.BoxTitle{
		position: absolute;
		color: #fff;
		text-transform: uppercase;
		font-size: 2em;
		font-weight: lighter;
	    bottom: 9%;
	    left: 50%;
	    @include transform($center);
		width: 100%;
		text-align: center;
		padding: 10px 20px;
		&:before{
			content:'';
			position: absolute;
			bottom: -10px;
			left: 50%;
			width: 20%;
			height: 2px;
			display: block;
			background-color: #fff;
			@include transition(1s);
			@include transform(translateX(-50%));
		}
	}
	img{
		width: 46%;
		position: absolute;
	    top: 40%;
	    left: 50%;
	    @include transform($center);
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		display: block;
		width: 100%;
		height: 100%;
	}
	&:hover{
		.boxbg{
			@include transform(scale(1.2) !important);
		}
		.BoxTitle:before{
			width: 40% !important;
		}
	}
}
#pageBoxes{
	.box{
		padding-bottom: 25%;
	}
}
.box-1{
	background-color: #06cdf0;
}
.box-4{
	background-color: #c087bc;
}

/*** Temporary ***/
.box-3,
.box-2{
	img{
		display: none !important;
	}
}
/*** Temporary ***/
#sliderOneContainer{
	overflow: hidden;
}
#sliderOne{
	section {
		padding-bottom: 100%;
	}
}
.sliderText{
	position: absolute;
	z-index: 3000;
	bottom: 10%;
	left: 50%;
	width: 71%;
	text-align: center;
	font-weight: lighter;
	background-color: rgba(43,43,43,0.7);
	text-transform: uppercase;
	color: #fff;
	font-size: 2em;
	padding: 25px 35px;
	@include transform(translateX(-50%));
}
.slideIn{
	opacity: 0;
	@include transform(rotateY(40deg) translateX(50%));
}
#Home2{
	overflow: hidden;
    z-index: 300;
	perspective: 1000px;
	-o-perspective: 1000px;
	-ms-perspective: 1000px;
	-moz-perspective: 1000px;
	-webkit-perspective: 1000px;
}
#pageBoxes.activeIn,
#Home2.activeIn{
	.slideIn{
		opacity: 1;
		@include transform(rotateY(0deg) translateX(0%) !important);
	}
}

.pageTitle{
	color: #484853;
	text-transform: uppercase;
	font-weight: lighter;
	font-size: 3em;
	padding: 20px 0px;
}

#Home3{
	padding: 100px 20px;
	overflow: hidden;
	min-height: 100vh;
	.inner{
		z-index: 4;
	}
	.pageTitle{
		color: #484853 !important;
	}
	.leftImg{
		position: absolute;
		bottom: 0px;
		left: -5%;
		width: 20%;
	}
	.rightImg{
		position: absolute;
		bottom: 0px;
		right: 0px;
		width: 15%;
	}

}
.NewsSlideInner{
	padding: 20px;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.NewsImgContainer{
		background-color: #fff;
		padding-bottom: 70%;
		overflow: hidden;
		.newsImage{
			top: 0px;
			left: 0px;
			width: 101%;
			height: 101%;
			position: absolute;
			background-size: cover;
			@include transform(scale(1));
			@include transition(all 1s);
			background-position: center;
			background-image: url(../images/img1.jpg); 
		}
	}
	.NewsContentContainer{

		@include transition(all 1s);
		background-color: #fff;
		border: 5px solid #eee;
		border-top: 0px !important;
		padding: 25px;
		/*min-height: 150px; */
		.NewsTitle{
			color: #484853;
			text-transform: uppercase;
			font-weight: lighter;
			font-size: 1.8em;
		}
		.NewsHover{
			position: absolute;
		    opacity: 0; 
		    left: 0px;
		    top: 0px;
		    text-align: center;
		    height: 100%;
		    width: 100%;
		    background-color: #c087bc;
		    @include transition(all 1s);
		    span{
		    	color: #fff;
		    	text-transform: uppercase;
		    	font-size: 1.2em;
		    	position: absolute;
		    	top: 42%;
		    	font-weight: lighter;
		    	left: 50%;
		    	@include transform($center);
		    	display: inline-block;
		    	&:before{
		    		display: block;
		    		position: absolute;
		    		content: '';
		    		bottom: -20px;
		    		height: 2px;
		    		width: 40%;
		    		left: 50%;
		    		background-color: #fff;
		    		@include transition(all 1s);
		    		@include transform(translateX(-50%));
		    	}
		    }
		}
	}
	&:hover{
		.NewsContentContainer{
			border-color: #c087bc !important;
		}
		.NewsImgContainer{
			.newsImage{
				@include transform(scale(1.2) !important);
			}
		}
		.NewsHover{
			opacity: 1 !important;
			span{
				&:before{
					width: 70% !important;
				}
			}
		}
	}
}
.home3Group{
	opacity: 0;
}
#Home3.activeIn{
	.home3Group{
		opacity: 1 !important;
	}
}

.BreadcrumbDivider{
    display: inline-block;
    vertical-align: middle;
    margin-top: -8px;
}
#breadcrumbs{
	*{
		display: inline-block;
	}
}

#newsNext:before,
#newsPrev:before,
#newsNext:after,
#newsPrev:after{
	@include transition($t1);
	content: '';
	display: block;
	position: absolute;
	height: 2px;
	background-color: #c087bc;
	width: 19px;
	left: 50%;
	top: 50%;
	
}
#newsNext:before,
#newsPrev:before{
	@include transform($center rotate(45deg));
}
#newsNext:after,
#newsPrev:after{
	@include transform($center rotate(-45deg));
}
#newsNext:after {
    margin-left: 2px;
    margin-top: 6px;
}
#newsNext:before {
    margin-left: 2px;
    margin-top: -6px;
}

#newsPrev:before {
    margin-left: -2px;
    margin-top: 6px;
}
#newsPrev:after {
    margin-left: -2px;
    margin-top: -6px;
}
#newsNext,
#newsPrev{
	margin: 0px 10px;
	@include transition($t1);
	display: inline-block;
	width: 40px;
	height: 40px;
	border: 1px solid #494954;
	position: relative;
	background-color: #fff;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
		z-index: 1;
	}
	&:hover{
		border: 1px solid #c087bc;
		background-color: #c087bc;
	}
	&:hover:after,
	&:hover:before{
		background-color: #fff !important;
	}
}

.ReadMore{
	padding: 6px 20px;
    margin-left: 30px;
	@include transition($t1);
	border: 1px solid #494954;
	color: #fff;
	height: 40px;
	text-transform: uppercase;
	font-weight: lighter;
	color: #c087bc;
	background-color: #fff;
	display: inline-block;
	&:hover{
		color: #fff !important;
		border: 1px solid #c087bc;
		background-color: #c087bc !important;
	}
}
.NewSliderControls{
	padding-top: 40px;
	*{
		vertical-align: top;
	}
}
#Home4{
	background-image: url(../images/img3.jpg);
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
	padding: 100px 20px;
	overflow: hidden;
	min-height: 100vh;
	.pageTitle{
		color: #fff !important;
	}
	.inner {
	    width: 1800px;
	}
	.darkenSection{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-color: rgba(35, 38, 45, 0.7);
	}
	.ReadMore{
		padding: 6px 20px;
	    margin-left: 30px;
		@include transition($t1);
		border: 1px solid #fff;
		color: #fff;
		height: 40px;
		text-transform: uppercase;
		font-weight: lighter;
		color: #06cdf0;
		background-color: transparent;
		display: inline-block;
		&:hover{
			color: #fff !important;
			border: 1px solid #06cdf0;
			background-color: #06cdf0 !important;
		}
	}
}

#EventsNext:before,
#EventsPrev:before,
#EventsNext:after,
#EventsPrev:after{
	@include transition($t1);
	content: '';
	display: block;
	position: absolute;
	height: 2px;
	background-color: #06cdf0;
	width: 19px;
	left: 50%;
	top: 50%;
	
}
#EventsNext:before,
#EventsPrev:before{
	@include transform($center rotate(45deg));
}
#EventsNext:after,
#EventsPrev:after{
	@include transform($center rotate(-45deg));
}
#EventsNext:after {
    margin-left: 2px;
    margin-top: 6px;
}
#EventsNext:before {
    margin-left: 2px;
    margin-top: -6px;
}

#EventsPrev:before {
    margin-left: -2px;
    margin-top: 6px;
}
#EventsPrev:after {
    margin-left: -2px;
    margin-top: -6px;
}
#EventsNext,
#EventsPrev{
	margin: 0px 10px;
	@include transition($t1);
	display: inline-block;
	width: 40px;
	height: 40px;
	border: 1px solid #fff;
	position: relative;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
		z-index: 1;
	}
	&:hover{
		border: 1px solid #06cdf0;
		background-color: #06cdf0;
	}
	&:hover:after,
	&:hover:before{
		background-color: #fff !important;
	}
}
.home4Group{
	opacity: 0;
}
#Home4.activeIn{
	.home4Group{
		opacity: 1 !important;
	}
}
.EventsSliderControls{
	padding-top: 40px;
	*{
		vertical-align: top;
	}
}

.EventsSlideInner{
	padding: 20px;
	.top{
		.topInnerContainer{
			padding-top: 20px;
			padding-bottom: 10px;
			@include transition($t1);
			.topInner{
				padding: 20px;
				background-color: #fff;
				.date{
					text-align: center;
					padding-bottom: 15px;
					&:after{
						content: '';
						position: absolute;
						width: 2px;
						height: 80%;
						right: 0px;
						top: 50%;
						@include transform(translateY(-50%)translateX(50%));
						background-color: #0eb1cf;
					}
					.day{
						font-size: 6.5em;
					    font-weight: lighter;
					    color: #0eb1cf;
					    display: block;
					}
					.month{
						text-transform: uppercase;
					    font-size: 2em;
					    font-weight: lighter;
					    color: #484853;
					    display: block;
					}
				}
				.eventContent{
					padding: 12px 20px;
					text-transform: uppercase;
					*{
						color: #484853;
					}
				}	
			}
		}
	}
	.bottom{
		text-align: center;
		background-color: #06cdf0;
		padding: 10px 10px;
		text-transform: uppercase;
		font-weight: lighter;
		color: #fff;
		@include transition($t1);
		img{
			@include transition($t1);
			position: absolute;
			top: 50%;
			right: 40px;
			opacity: 0;
			@include transform($center);
		}
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	&:hover{
		.bottom{
			padding: 20px 10px !important;
		}
		.top{
			.topInnerContainer{
				padding-top: 0px !important;
			}
		}
		.bottom{
			img{
				opacity: 1 !important;
			}
		}
	}
}
#Home5{
	padding: 100px 20px;
	overflow: hidden;
	min-height: 100vh;
	background-color: #484853;
	.pageTitle{
		color: #fff !important;
	}
}
/*** Percentacge housepoints ***/
#svg1 circle,
#svg2 circle,
#svg3 circle,
#svg4 circle,
#svg5 circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
  stroke: #5b5b65;
  stroke-width: 1px;
}
#svg1 #bar1,
#svg2 #bar2,
#svg3 #bar3,
#svg4 #bar4,
#svg5 #bar5 {
  stroke-width: 6px;
}
#svg1 #bar1{
  stroke: #c15959;
}
#svg2 #bar2{
  stroke: #05cdf0;
}
#svg3 #bar3{
  stroke: #ddba40;
}
#svg4 #bar4{
  stroke: #55c2b5;
}
#svg5 #bar5 {
  stroke: #d4892e;
}
#cont1,
#cont2,
#cont3,
#cont4,
#cont5 {
  display: block;
  height: 200px;
  width: 200px;
  margin: 2em auto;
  border-radius: 100%;
  position: relative;
}
.housePointTitle{
	text-transform: uppercase;
	font-weight: lighter;
	color: #fff;
	font-size: 2em;
	padding: 20px 0px;
	&:before{
		content:'';
		position: absolute;
		bottom: -10px;
		left: 50%;
		width: 20%;
		height: 2px;
		display: block;
		background-color: #fff;
		@include transform(translateX(-50%));
	}
}
.title-1:before{background-color: #c15959;}
.title-2:before{background-color: #05cdf0;}
.title-3:before{background-color: #ddba40;}
.title-4:before{background-color: #55c2b5;}
.title-5:before{background-color: #d4892e;}


.HousePointsCount{
	text-transform: uppercase;
	font-weight: lighter;
	color: #fff;
	font-size: 5em;
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform($center);
}
.tpink{
	color: #c087bc !important;
}

#address{
	position: absolute;
	top: 0px;
	left: 40px;
	max-width: 615px;
	width: 40%;
	background-color: #fff;
	height: 100%;
	padding: 40px 70px;
	*{
		color: #484853;
		font-weight: lighter;
	}
	#addressLogo{
		width: 140px;
		margin-bottom: 30px;
		margin-top: 60px;
	}
}

footer{
	padding: 25px;
	background-color: #484853;
	*{
		color: #fff !important;
		font-weight: lighter;
	}
	.fse{
		margin-bottom: 0px !important;
		font-size: 0.6em;
	}
	.footer2,
	.footer1{
		font-size: 0.9em;
		line-height: 1.55em;
	}
	.footer1{
		font-weight: normal !important;
	}
}
/*** Site build ***/

/*#Transition{
	display: none;
	position: fixed;
	z-index: 9999;
	left: 0px;
	top: 0px;
	opacity: 1;
	background-color: rgba(0,0,255, 0.6);
	width: 100%;
	height: 100%;
}*/

.pace-done{
	/*#Transition{
		width: 0%;
		opacity: 0 !important;
	}*/
}

/*** Side Nav **/
#SideNav{
	position: fixed;
	top: 50%;
	right: 0px;
	opacity: 1;
	@include transition($t1);
	@include transform(translateY(-50%));
	z-index: 9002;

	img{
		width: 14px;
		margin-right: 4px;
		vertical-align: middle;
		display: inline-block;	
	}

	.slink{
		color: #000;

	}
	.dot{
		display: block;
    	float: right;
		width: 50px;
		height: 13px;
		margin-top: 4px;
		margin-left: 5px;
		@include transition($t1);
		vertical-align: middle;
		position: relative;
		&:before{
			display: block;
			content: '';
			height: 100%;
			width: 0%;
			position: absolute;
			top: 0px;
			right: 0px;
			background-color: #c087bc;
			@include transition($t1);
		}
	}
	.sectionTitle{
		@include transition($t1);
		font-size: 0.8em;
		vertical-align: middle;
		display: block;
    	float: left;
    	font-weight: bold;
		color: #999 !important;
		margin-top: 2px;
	}
	.slink{
		padding: 0px 0px 0px 0px;
		cursor: pointer;
		margin-top: 9px;
		&:hover{
			.sectionTitle{
				color: #999 !important;
				opacity:  1 !important;
			}
			.dot{
				&:before{
					width: 100%;
					background-color: #06cdf0;
				}
			}
		}
	}
	.slink.active{
		
		.sectionTitle{
			color: #999 !important;
			opacity: 1;
		}
		.dot{
			&:before{
				width: 100%;
			}
		}
	}
}
.bgWhite{
	background-color: #fff;
}
#breadcrumbs{
	background-color: #484853;
	padding: 15px;
    font-size: 1em;
	*{
		font-size: 1em;
		color: #fff !important;
		font-weight: lighter;
		text-transform: uppercase;
	}
}

/*** Sports Academy Page ***/
#SportsAcademyFooter{
	position: fixed;
	width: 100%;
	left: 0px;
	bottom: 0px;
	z-index: 3;
}
#SportsAcademyContainer{
	perspective: 2000px;
	-o-perspective: 2000px;
	-ms-perspective: 2000px;
	-moz-perspective: 2000px;
	-webkit-perspective: 2000px;
	background-color: #2b2b2b;
	overflow: hidden;
}
.SportsAcademyPage{
	background-color: #2b2b2b;
}
.LargeSection{
	width: 100%;
}
.LargeSection,
.SmallSection,
.SportsAcademyInnerSection,
#SportsAcademyContainer{

	float: left;
	position: relative;
	/* box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.6); */
		-webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
    	backface-visibility: hidden;
    *{
    	-webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
    	backface-visibility: hidden;
    }
}
.LargeSection{
	opacity: 0;
	@include transition(all 1s);
	@include transform(translateX(50%) rotateY(100deg) !important);
}
.SmallSection {
	opacity: 0;
	@include transform(translateX(50%) rotateY(100deg) !important);
}
.SmallSection-1{
	@include transition(all 1s);
	background-color: #06cdf0;
	.boxbg,
	.boxDarken{
		opacity: 0 !important;
	}
}
.SmallSection-2{
	@include transition(all 1s 0.2s);
	img{
		opacity: 0 !important;
	}
	.BoxTitle:before{
		background-color: #06cdf0 !important;
	}
}
.SmallSection-3{
	@include transition(all 1s 0.4s);
	
	img{
		opacity: 0 !important;
	}
	.BoxTitle:before{
		background-color: #06cdf0 !important;
	}
}
.SmallSection-4{
	@include transition(all 1s 0.8s);
	background-color: #c087bc;
	
	.boxbg,
	.boxDarken{
		opacity: 0 !important;
	}
}
.LargeSection-0{
	@include transition(all 1s);
	.BoxTitle:before{
		background-color: #c087bc !important;
	}
}
.spa-5{
	@include transition(all 1s 1s);
}
.LargeSection,
.SmallSection{
	overflow: hidden;
	.boxbg{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 101%;
		height: 101%;
		background-image: url(../images/img1.jpg);
		background-size: cover;
		background-position: center;
		@include transition(1s);
		@include transform(scale(1));
	}
	
	.boxDarken{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 101%;
		height: 101%;
		background-color: rgba(43,43,43,0.89);
	}
	.BoxTitle{
		position: absolute;
		color: #fff;
		text-transform: uppercase;
		font-size: 2em;
		font-weight: lighter;
	    bottom: 9%;
	    left: 50%;
	    @include transform($center);
		width: 100%;
		text-align: center;
		padding: 10px 20px;
		&:before{
			content:'';
			position: absolute;
			bottom: -10px;
			left: 50%;
			width: 20%;
			height: 2px;
			display: block;
			background-color: #fff;
			@include transition(1s);
			@include transform(translateX(-50%));
		}
	}
	img{
		width: 46%;
		position: absolute;
	    top: 40%;
	    left: 50%;
	    @include transform($center);
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 101%;
		height: 101%;
	}
	&:hover{
		.boxbg{
			@include transform(scale(1.2) !important);
		}
		.BoxTitle:before{
			width: 40% !important;
		}
	}
}
.LargeSection.active,
.SmallSection.active{
	opacity: 1 !important;
	@include transform(translateX(0%) rotateY(0deg) !important);
}
.halfWidth .col-6{
	width: 100%;
	min-width: 100% !important;
}
.LargeSection .BoxTitle{
    max-width: 69%;
    background-color: #484853;
    padding-bottom: 40px;
    padding-top: 20px;
    &:before{
    	bottom: 23px;
    }
}
.LargeSection .boxDarken{
	background-color: rgba(43,43,43,0.1);
}
.LargeSection img{
    @include transform(translate(0%,0%));
    top: auto;
    left: auto;
    bottom: 0px;
    right: 0px;
}
#PageWrapper,
#main{
	position:relative;
	float: left;
	width: 100%;
}

.SportsAcademyPage{
	#Home6{
		display: none !important;
	}
}

.fullHeight{
	height: 100vh;
}

.scrollArrow{
	cursor: pointer;
	position: absolute;
	top: 50%;
	right: 40px;
	@include transition($t1);
	@include transform(translateY(-50%));
}

.scrollArrow.back{
	@include transform(rotate(-180deg) translateY(50%));
}

#SportsPageContent{
	display: none;
	position: fixed;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
	color: #fff;
	background-color: #2b2b2b;
	*{
		color: #fff;
	}
}

#SportsAcademyContainerOuter.Free{
	position: absolute;
    top: 50%;
    left: 50%;
    @include transform($center);
}
#PageContainer.SportsAcademyInnerPage{
	color: #2b2b2b;
	*{
		color: #2b2b2b;
	}
	.searchToggle{
		background-color: transparent;
	}
}

.HiddenPageImage{
	position: fixed;
	/* background-image: url(../images/img1.jpg);
	background-size: cover; */
	overflow: hidden;
	width: 0%;
	height: 100%;
	top: 0px;
	/* opacity: 0; */
	right: 0%;
	/* @include transition(2s); */
	.boxbg{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 101%;
		height: 101%;
		background-image: url(../images/img1.jpg);
		background-size: cover;
		background-position: center;
		@include transform(scale(1));
		@include transition(14s);
	}
	.boxGrad{
		position: absolute;
		top: 0px;
		opacity: 0;
		@include transition(1s);
		left: 0px;
		width: 101%;
		height: 101%;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,06cdf0+87,06cdf0+100&0+0,1+100 */
		background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(6,205,240,0.87) 87%, rgba(6,205,240,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(6,205,240,0.87) 87%,rgba(6,205,240,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(6,205,240,0.87) 87%,rgba(6,205,240,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#06cdf0',GradientType=1 ); /* IE6-9 */
	}
	
	.boxDarken{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 101%;
		height: 101%;
		background-color: rgba(43,43,43,0.7);
	}
}
.HiddenPageContent{
	position: fixed;
    right: 0%;
    width: 100%;
    opacity: 0;
    /* padding: 100px 5% 100px 55%; */
    padding: 200px 5% 100px 55%;
    font-weight: lighter;
    max-height: 100%;
    overflow: auto;
    z-index: 2;
    color: #fff;




	*{
		color: #fff;
	}
}
.DisplayContent{
	
	.HiddenPageImage{
		width: 50%;
		/* right: 0%; */
		opacity: 1;
		.boxbg{
			@include transform(scale(1.2));
		}
	}

	
}

.hideContent{
	height: 0px !important;
	padding: 0px !important;
	overflow: hidden !important;
	visibility: hidden !important;
}

.SportsAcademyInnerPage,
.activeSportsPageLink{
	.scrollArrow{
		@include transform(translateY(-50%) rotate(90deg));
	}
	.searchToggle{
		background-color: transparent;
	}
}
.innerTitle{
    font-size: 3em;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 50px;
    &:after{
    	content:'';
    	display: block;
    	height: 2px;
    	left: 0px;
    	width: 60px;
    	bottom: -20px;
    	position: absolute;
    	background-color: #fff;
    }
}

.scrollArrow.disabled{
	opacity: 0;
}

.PinkPage{
	background-color: #c087bc !important;
	.boxGrad{
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,c087bc+90,c087bc+100&0+0,1+94,1+100 */
		background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(192,135,188,0.96) 90%, rgba(192,135,188,1) 94%, rgba(192,135,188,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(192,135,188,0.96) 90%,rgba(192,135,188,1) 94%,rgba(192,135,188,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(192,135,188,0.96) 90%,rgba(192,135,188,1) 94%,rgba(192,135,188,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#c087bc',GradientType=1 ); /* IE6-9 */
	}
}
.BluePage{
	background-color: #06cdf0 !important;
}

.SideBarSports{
	z-index: 700;
	position: fixed;
	top: 20%;
	left: 0px;
	opacity: 0;
	@include transition($t1);
	.dot{
		display: inline-block;
		width: 50px;
		height: 13px;
		margin-right: 5px;
		@include transition($t1);
		vertical-align: middle;
		position: relative;
		&:before{
			display: block;
			content: '';
			height: 100%;
			width: 0%;
			position: absolute;
			top: 0px;
			left: 0px;
			background-color: #c087bc;
			@include transition($t1);
		}
	}
	.sectionTitle{
		@include transition($t1);
		font-size: 0.8em;
		vertical-align: middle;
		display: inline-block;
		color: #fff !important;
		margin-top: 2px;
	}
	.slink{
		padding: 0px 5px 0px 0px;
		cursor: pointer;

		&:hover{
			.sectionTitle{
				color: #999 !important;
				opacity:  1 !important;
			}
		}
	}
	a:hover{
		
		.sectionTitle{
			color: #999 !important;
			opacity: 1;
		}
		.dot{
			&:before{
				width: 100%;
			}
		}
	}
}

.AnimateContent{
	.HiddenPageContent{
		opacity: 0 !important;
	}
	.SideBarSports,
	.HiddenPageContent{
		opacity: 1 !important;
	}

	.boxGrad{
		opacity: 1 !important;
	}
	.HiddenPageImage{
		right: 50%;
	}
}

.fancybox-overlay {
    z-index: 9999;
}


.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		text-transform: uppercase;
		font-weight: lighter;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bototm: 20px;
	}
}

.bold{
	font-weight: bold !important;
}

.staffInfo{
    font-size: 0.8em;
}

.innerContent,
#content{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	    max-width: 400px;
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
	    	max-width: 400px;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		   	background-color: #484853;
			&:hover{
		    	background-color: #c087bc !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}

.ContactForm{
	.innerContent{
		input[type=submit],
		input[type=text],
		input[type=email],
		input[type=password],
		textarea{
			max-width: 100% !important;
		}
	}
}

.formContainer{
	max-width: 600px;
	min-width: 0px !important;
}

.innerContent{
	padding-left: 20px;
	/*padding-right: 20px;*/
	padding-right: 20%;
}
/*
.no-sidebar{
	.innerContent{
		padding-right: 20px !important;
	}
}*/

.NoticesLogo{
	position: absolute;
    width: 100px;
    left: 50%;
    @include transform($center);
    top: 0px;
}

.NoticeTitle{
    color: #c087bc;
    text-align: center;
    margin-top: 40px;
    font-size: 1.7em;
}



/*** Menu ***/
#Menu{
    right: 40px; //100px;
    z-index: 9999;
	position: fixed;
	display: inline-block;
	text-align: right;
	*{
		color: #fff;
		font-weight: lighter;
    	vertical-align: middle;
	}
	.MenuButton{
		display: inline-block;
		position: absolute;
		z-index: 2;
		cursor: pointer;
		    background-color: #73a8d9;
	    right: 0px;
	    padding: 8px 12px 7px 12px;
	    cursor: pointer;
	    opacity: 1;
	    top: 0px;
	    text-transform: uppercase;
	}
	.MenuContainer{
		position: relative;
//		z-index: -9999;
//		margin-top: -600px;
		.LevelOne{
			display: inline-block;
			text-align: center;
			position: relative;
			a{
				padding: 14px 10px;
			    text-transform: uppercase;
			    font-size: 0.7em;
			    line-height: 3em;
			    display: inline-block;
	   	 		line-height: 1.1em;
	   	 		@include transition($t1);
			}
		}
	}
	.SubMenu{
		@include box-shadow(-2px 13px 40px 0px rgba(47, 47, 47, 0.33));
		width: 250px;
		height: 0px;
		overflow: hidden;
		display: block;
		z-index: -9999;
    	left: 50%;
    	text-align: left;
		position: absolute;
	    background-color: #c087bc;
	    border-radius: 0px;
	    margin-top: -1px;
	   /* padding: 3px 10px 6px 10px;*/
	    opacity: 0;
	    transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -o-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -ms-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -moz-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -webkit-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    @include transform(translateY(20px) translateX(-50%) scale(0.98));

	    a{
		    display: inline-block;
		    padding: 10px 20px !important;
		    /*font-size: 0.9em;*/
		    line-height: 1.2em !important;
		    width: 100%;
			@include border-radius(3px);
			background-color: transparent;
	    	@include transition($t1);
			&:hover{
    			background-color: #a76ea3 !important;
			}
		}
	}

	.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(-50%) scale(1) !important);
	}

	/*.SubMenu:before {
	    content: '';
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 17px;
	    left: 0px;
	    top: -14px;
	    z-index: 2;
	}
	.SubMenu:after {
	    content: '';
	    display: block;
	    position: absolute;
	    border: 10px solid transparent;
	    top: -20px;
	    left: 50%;
	    margin-left: -10px;
	    border-bottom: 10px solid #2f3b67;
	}*/
}
.topMenuLink{
	position: relative;

}

.LevelOne.active{
	.topMenuLink{
		color: #c087bc !important;
	}
}
.topMenuLink:after{
	background-color: #c087bc;
	right: 0px;
	content:'';
	position: absolute;
	top: 0px;
	top: 50%;
    width: 2px;
    height: 34%;
    @include transform(translateY(-50%));
}

.HomePage .MenuButton{
	background-color: #0759a7 !important;
}

.LevelOne a{
	@include transition($t1);
}
.LevelOne.active a.topMenuLink{
	/*color: #fff !important;*/
}
.LevelOne:last-of-type a:after{
	display: none !important;
}
//.menuGroup{
//	opacity: 0;
//	margin-right: -20px;
//}
.MainMenuClose{
	display: inline-block;
	margin-top: -2px;
    DISPLAY: NONE;
}

.topBar{
	top: 0px;
	height: 34px;
	width: 0%;
	right: 0px;
	z-index: 9998;
	position: fixed;
	display: none;
	background-color: #0759a7;
}
//.ActiveMenu{
//	.topBar{
//		width: 100% !important;
//	/*	opacity: 0;*/
//	}
//	.menuGroup{
//		opacity: 1 !important;
//		margin-right: 0px !important;
//	}
//	.MenuButton{
//		top: -50px !important;
//		opacity: 0 !important;
//	}
//	.MenuContainer{
//		z-index: 1 !important;
//		margin-top: 0px !important;
//	}
//}
/*** Menu ***/

#PageFooter{
	.slink{
	    background-color: #06cdf0;
	    width: 82px;
	    height: 100%;
	    position: absolute;
	    top: 0px;
	    right: 0px;
	    cursor: pointer;
	    img{
	    	position: absolute;
	    	top: 50%;
	    	left: 50%;
	    	@include transform($center);
	    }
	}
	.fse {
	    margin-bottom: 0px !important;
	    font-size: 0.6em;
	    padding-right: 90px;
	    margin-top: 8px;
	}
}

.SportsAcademyPage{
	#PageFooter{
		display: none !important;
	}
}

.PageBoxesHide,
.SportsAcademyInnerPage,
.SportsAcademyPage{
	#pageBoxes{
		display: none !important;
	}
}

#breadcrumbs {
    background-color: #484853;
    padding: 15px 3%;
    font-size: 1em;
}

.HeaderImageText{
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    right: 0px;
    width: 100%;
    max-width: 1000px;
    text-align: right;
    padding: 20px 5%;
    color: #fff;
    text-transform: uppercase;
    font-weight: lighter;
    margin-top: 21px;
    font-size: 2.5em;
}

.activeVideo{
	overflow: hidden !important;
	height: 100vh !important;
	.SearchContainer,
	.searchToggle,
	#NavBarLogo{
		display: none !important;
	}
}
/*.innerContent{
	.col-12{
		opacity: 0;
		@include transition(opacity 1s);
	}
}

.activePage{
	.innerContent{
		.col-12{
			opacity: 1 !important;
		}
	}
}*/

strong{
	font-weight: bold !important;
}

.dept{
	margin-bottom: 0px !important
}

.innerContent label{
	margin-left: 0px !important;
}

.sportsUploads{
	.upload {
	    border: 1px solid #000000 !important;
	}
    .upload .fileIcon span{
    	color: #fff !important;
    }
    .upload .fileIcon {
    	background-color: #6d6d6d !important;
    }
    .upload:hover {
	    background-color: rgba(142, 141, 141, 0.25) !important;
	    color: #000 !important;
	}
}

/*** Scroll Down ***/
#ScrollDown{
	position: absolute;
	bottom: 50px;
	left: 50%;
	@inclue transform(translateX(-50%));
	color: #fff;
	text-transform: uppercase;
	display: inline-block;
	padding-bottom: 30px;
	cursor: pointer;
	span{
		display: inline-block;
		padding-bottom: 30px;
		text-transform: uppercase;
		color: #fff;
		position: relative;
		@include transform(translateX(-50%));
		&:after,
		&:before{
			content:'';
			background-color: #fff;
			height: 2px;
			width: 12px;
			display: block;
			position: absolute;
			left: 50%;
			bottom: 20px;
		}
		&:after{
			margin-left: 3px;
			@include transform( translateX(-50%) rotate(-45deg));
		}
		&:before{
			margin-left: -5px;
			@include transform( translateX(-50%) rotate(45deg));
		}
	}
}
/*** Scroll Down ***/


#sliderOneContainer {
    position: absolute;
    top: 0px;
    left: 0px;
}

